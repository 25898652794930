import React, { Component } from "react";
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
  TextField,
} from "@material-ui/core";
import { GetData } from "./../../api/service";
import { Alert } from "antd";
import moment from 'moment'

import PlayCircleOutlineOutlined from '@material-ui/icons/PlayCircleOutlineOutlined';


const styles = (theme) => ({
  colorRed: {
    color: "red",
  },
});

class ExtraClassModal extends Component {
  state = {
    date: moment().format("YYYY-MM-DD"),
    term_name: "",
    term_list: [1, 2, 3, 4, 5, 6],
    isLoaded: false,
    streams: [],
    courses: [],
    sessions: [],
    subjects: [],
    filter_subjects: [],
    section: "",
    course: "",
    stream: "",
    semester: "",
    sub_code: "",
    course_type: "",
    day: moment().format('ddd'),
    errors: [],
    dept_code: "",
    period_number: this.props.selectedSlot?.slotId,
    departments: [],
  };

  componentDidMount() {
    GetData("/getcourse")
      .then((resp) => {
        this.setState({
          courses: resp,
        });
      })
      .then(() => {
        GetData("/getsubject")
          .then((resp) => {
            this.setState({
              subjects: resp,
              isLoaded: true,
            });
          })
          // .then(() => {
          //   GetData("/getdepartment").then((resp) => {
          //     this.setState({
          //       departments: resp,
                
          //     });
          //   });
          // });
      });
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    //console.log(e.target.name)

    if (e.target.name === "course") {
      let term_name = "";
      let term_list = [];
      let course = this.state.courses;
      let results = course.filter((el) => el.name === e.target.value);
      if (results.length > 0) {
        term_name = results[0].tenure;
        let no_of_term = parseFloat(results[0].no_of_term);
        for (let i = 1; i <= no_of_term; i++) {
          term_list.push(i);
        }
        this.setState({
          term_name,
          term_list,
        });
      }
    } 
    if (e.target.name == "course") {
      let f = this.filterSubjectsByCourse(e.target.value, this.state.semester)
     
      this.setState({
        filter_subjects: f.length > 0 ? f : [],
      });
    }

    if (e.target.name == "semester") {
      let f = this.filterSubjectsByCourse(this.state.course, e.target.value)
     
      this.setState({
        filter_subjects: f.length > 0 ? f : [],
      });
    }
  };


  filterSubjectsByCourse = (courseName, semester) => {
    return this.state.subjects.filter(subject => 
      subject.course.some(course => course.name === courseName) 
    );
}

  handleValidation = () => {
    let {
      session,
      course,
      course_type,
      day,
      sub_code,
      semester,
      period_number,
      dept_code,
      errors,
    } = this.state;
    let isValid = true;

    //console.log(this.state)

    // if (session === '') {
    //     isValid = false;
    //     errors['session'] = 'Cannot be empty';
    // } else {
    //     errors['session'] = '';
    // }

    if (course === "") {
      isValid = false;
      errors["course"] = "Cannot be empty";
    } else {
      errors["course"] = "";
    }

    if (course == "BA" || course == "BSC" || course == "BCOM") {
      if (course_type === "") {
        isValid = false;
        errors["course_type"] = "Cannot be empty";
      } else {
        errors["course_type"] = "";
      }
    } else errors["course_type"] = "";

    if (day === "") {
      isValid = false;
      errors["day"] = "Cannot be empty";
    } else {
      errors["day"] = "";
    }

    if (sub_code === "") {
      isValid = false;
      errors["sub_code"] = "Cannot be empty";
    } else {
      errors["sub_code"] = "";
    }

    if (semester === "") {
      isValid = false;
      errors["semester"] = "Cannot be empty";
    } else {
      errors["semester"] = "";
    }

    if (period_number === "") {
      isValid = false;
      errors["period_number"] = "Cannot be empty";
    } else {
      errors["period_number"] = "";
    }

    // if (dept_code === "") {
    //   isValid = false;
    //   errors["dept_code"] = "Cannot be empty";
    // } else {
    //   errors["dept_code"] = "";
    // }

    this.setState({
      errors,
    });

    return isValid;
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidation()) {
      //submit only validated data
      let {
        section,
        course_type,
        course,
        stream,
        day,
        sub_code,
        semester,
        period_number,
        dept_code,
        date,
      } = this.state;
      let data = {
        course: course,
        dept_code: dept_code,
        semester: semester,
        stream: stream,
        sub_code: sub_code,
        period_number: period_number,
        section: section,
        date: date, // newly-added
        course_type,
      };

      this.props.history.push("/takeattendance", data);
    }
  };


  checkIfSubjectHasSection = () => {
    if(this.state.sub_code != ""){
      let dd = this.state.subjects.find(el =>el.sub_code == this.state.sub_code);
      console.log("🚀 ~ ExtraClassModal ~ dd:", dd)
      if(dd){
        if(dd.has_section == true){
          return true;
        }else{
          return false;
        }
      }else{
        return false;
      }
    }
  }

  render() {
    let { classes } = this.props;
    let stream = [];
    let course = [];
    let session = [];
    let subject = [];
    let semester = [];
    let department = [];
    if (this.state.isLoaded) {
      let i = this.state.courses;
      if (Array.isArray(i) && i.length > 0) {
        course = i.map((el, index) => (
          <MenuItem key={index} value={el.name}>
            {el.name}
          </MenuItem>
        ));
      }

      let k = this.state.term_list;
      if (Array.isArray(k) && k.length > 0) {
        semester = k.map((el, index) => (
          <MenuItem key={index} value={el}>
            {el}
          </MenuItem>
        ));
      }

      let m = this.state.filter_subjects;
      if (Array.isArray(m) && m.length > 0) {
        subject = m.map((el, index) => (
          <MenuItem key={index} value={el.sub_code}>
            {el.sub_name} :: {el.sub_code}
          </MenuItem>
        ));
      }

      let d = this.state.departments;
      if (Array.isArray(d) && d.length > 0) {
        department = d.map((el, index) => (
          <MenuItem key={index} value={el.dept_code}>
            {el.dept_name}
          </MenuItem>
        ));
      }
    }
    return (
      <div>
        <Dialog open={true} onClose={this.handleClose} scroll="body" fullWidth>
          <DialogTitle ><div style={{display: "flex", alignItems: "center", flexDirection: "row"}}> <PlayCircleOutlineOutlined /> &nbsp; <strong>Add Attendance</strong></div></DialogTitle>
          <Alert
            banner
            type="info"
            message={<>The attendance will be added against the date you have submitted. Choose the date carefully. <br />Use the "<strong>Section</strong>" option if applicable, otherwise leave it blank.</>}
          />
          <form onSubmit={this.onSubmit}>
            <DialogContent>
           
          
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} ms={6} lg={6}>
                  <FormControl
                    className={classes.formControl}
                    fullWidth
                    variant="filled"
                  >
                    <TextField
                      variant="filled"
                      label="Date"
                      name="date"
                      type="date"
                      margin="normal"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ marginTop: "1px" }}
                      value={this.state.date}
                      onChange={this.onChange}
                    />

                    <FormHelperText className={classes.colorRed}>
                      {this.state.errors["date"]}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} ms={6} lg={6}>
                  <FormControl
                    className={classes.formControl}
                    fullWidth
                    variant="filled"
                  >
                    <InputLabel shrink className={classes.textStyle}>
                      Select Course
                    </InputLabel>
                    <Select
                      value={this.state.course}
                      onChange={this.onChange}
                      name="course"
                      inputProps={{
                        name: "course",
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {course}
                    </Select>
                    <FormHelperText className={classes.colorRed}>
                      {this.state.errors["course"]}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                {this.state.course == "BA" ||
                this.state.course == "BSC" ||
                this.state.course == "BCOM" ? (
                  <Grid item xs={12} sm={12} ms={6} lg={6}>
                    <FormControl
                      required
                      className={classes.formControl}
                      fullWidth
                      variant="filled"
                    >
                      <InputLabel shrink className={classes.textStyle}>
                        Course Type
                      </InputLabel>
                      <Select
                        value={this.state.course_type}
                        onChange={this.onChange}
                        name="course_type"
                        inputProps={{
                          name: "course_type",
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="HONOURS">HONOURS</MenuItem>
                        {/* <MenuItem value="MAJOR">MAJOR</MenuItem> */}
                        <MenuItem value="REGULAR">REGULAR</MenuItem>
                        {/* <MenuItem value="GENERAL">GENERAL</MenuItem> */}
                      </Select>
                      <FormHelperText className={classes.colorRed}>
                        {this.state.errors["course_type"]}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                ) : null}

                {/* <Grid item xs={12} sm={12} ms={6} lg={6}>
                  <FormControl
                    className={classes.formControl}
                    fullWidth
                    variant="filled"
                  >
                    <InputLabel shrink className={classes.textStyle}>
                      Select Department
                    </InputLabel>
                    <Select
                      value={this.state.dept_code}
                      onChange={this.onChange}
                      name="dept_code"
                      inputProps={{
                        name: "dept_code",
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {department}
                    </Select>
                    <FormHelperText className={classes.colorRed}>
                      {this.state.errors["dept_code"]}
                    </FormHelperText>
                  </FormControl>
                </Grid> */}
                <Grid item xs={12} sm={12} ms={6} lg={6}>
                  <FormControl
                    className={classes.formControl}
                    fullWidth
                    variant="filled"
                  >
                    <InputLabel shrink className={classes.textStyle}>
                      Select {this.state.term_name}
                    </InputLabel>
                    <Select
                      value={this.state.semester}
                      onChange={this.onChange}
                      name="semester"
                      inputProps={{
                        name: "semester",
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {semester}
                    </Select>
                    <FormHelperText className={classes.colorRed}>
                      {this.state.errors["semester"]}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                
                <Grid item xs={12} sm={12} ms={6} lg={6}>
                  <FormControl
                    className={classes.formControl}
                    fullWidth
                    variant="filled"
                  >
                    <InputLabel shrink className={classes.textStyle}>
                      Select Day
                    </InputLabel>
                    <Select
                      value={this.state.day}
                      onChange={this.onChange}
                      name="day"
                      inputProps={{
                        name: "day",
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="Mon">Monday</MenuItem>
                      <MenuItem value="Tue">Tuesday</MenuItem>
                      <MenuItem value="Wed">Wednesday</MenuItem>
                      <MenuItem value="Thu">Thursday</MenuItem>
                      <MenuItem value="Fri">Friday</MenuItem>
                      <MenuItem value="Sat">Saturday</MenuItem>
                    </Select>
                    <FormHelperText className={classes.colorRed}>
                      {this.state.errors["day"]}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} ms={6} lg={6}>
                  <FormControl
                    className={classes.formControl}
                    fullWidth
                    variant="filled"
                  >
                    <InputLabel shrink className={classes.textStyle}>
                      Select Subject Code
                    </InputLabel>
                    <Select
                      value={this.state.sub_code}
                      onChange={this.onChange}
                      name="sub_code"
                      inputProps={{
                        name: "sub_code",
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {subject}
                    </Select>
                    <FormHelperText className={classes.colorRed}>
                      {this.state.errors["sub_code"]}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                {this.state.sub_code != "" && this.checkIfSubjectHasSection() && <Grid item xs={12} sm={12} ms={6} lg={6}>
                  <FormControl
                    className={classes.formControl}
                    fullWidth
                    variant="filled"
                  >
                    <InputLabel shrink className={classes.textStyle}>
                      Select Section
                    </InputLabel>
                    <Select
                      value={this.state.section}
                      onChange={this.onChange}
                      name="section"
                      inputProps={{
                        name: "section",
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="A">A</MenuItem>
                      <MenuItem value="B">B</MenuItem>
                      <MenuItem value="C">C</MenuItem>
                      <MenuItem value="D">D</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>}
                {/* <Grid item xs={12} sm={12} ms={6} lg={6}>
                  <FormControl
                    className={classes.formControl}
                    fullWidth
                    variant="filled"
                  >
                    <InputLabel shrink className={classes.textStyle}>
                      Period Number
                    </InputLabel>
                    <Select
                      value={this.state.period_number}
                      onChange={this.onChange}
                      name="period_number"
                      inputProps={{
                        name: "period_number",
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="1">1st</MenuItem>
                      <MenuItem value="2">2nd</MenuItem>
                      <MenuItem value="3">3rd</MenuItem>
                      <MenuItem value="4">4th</MenuItem>
                      <MenuItem value="5">5th</MenuItem>
                      <MenuItem value="6">6th</MenuItem>
                      <MenuItem value="7">7th</MenuItem>
                      <MenuItem value="8">8th</MenuItem>
                      <MenuItem value="9">9th</MenuItem>
                    </Select>
                    <FormHelperText className={classes.colorRed}>
                      {this.state.errors["period_number"]}
                    </FormHelperText>
                  </FormControl>
                </Grid> */}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                size="small"
                onClick={this.props.onCancel}
                color="primary"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                size="small"
                color="primary"
                variant="contained"
              >
                Create
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(ExtraClassModal);
