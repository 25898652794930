export const APP_NAME = "BHAWANIPUR_COLLEGE_TEACHER";
export const APP_GOOD_NAME = "BHAWANIPUR ANCHALIK COLLEGE";
export const DEFAULT_COLOR = "#2196F3";
export const DRAWER_WIDTH = 180;

export const BaseURL = process.env.NODE_ENV === 'production' ? 'https://api.bacollege.ac.in/public' : 'http://localhost/bhawanipur-main/api/public';
export const UploadPath = process.env.NODE_ENV === 'production' ? 'https://api.bacollege.ac.in/src/uploads' : 'http://localhost/bhawanipur-main/api/src/uploads';


export const ACCESS_CONTROL = [
    {
        text: 'ACCOUNTS',
        value: 'ACC'
    },
    {
        text: 'ADMISSION',
        value: 'ADM'
    },
    {
        text: 'FEES COLLECTION',
        value: 'FEE'
    },
    {
        text: 'REPORTS',
        value: 'REP'
    },
    {
        text: 'LEAVE-PRINCIPAL',
        value: 'LEAVEPRINCE'
    }
]


export const ATTENDANCE_SLOTS = [
    {
        slotId: 1,
        slotStart: "9:00 AM",
        slotEnd: "10:00 AM"
    },
    {
        slotId: 2,
        slotStart: "10:00 AM",
        slotEnd: "11:00 AM"
    },
    {
        slotId: 3,
        slotStart: "11:00 AM",
        slotEnd: "12:00 PM"
    },
    {
        slotId: 4,
        slotStart: "12:00 PM",
        slotEnd: "01:00 PM"
    },
    {
        slotId: 5,
        slotStart: "01:00 PM",
        slotEnd: "02:00 PM"
    },
    {
        slotId: 6,
        slotStart: "02:00 PM",
        slotEnd: "03:00 PM"
    },
    {
        slotId: 7,
        slotStart: "03:00 PM",
        slotEnd: "04:00 PM"
    },
    {
        slotId: 8,
        slotStart: "04:00 PM",
        slotEnd: "05:00 PM"
    }

]


export function getGoodSemester(course, semester){
    if(["HSA","HSS","HSC"].includes(course)){
        if(semester == 1){
            return '1st Year'
        }

        if(semester == 2){
            return '2nd Year'
        }
    }else{
        if(semester == 1){
            return '1st Semester'
        }

        if(semester == 2){
            return '2nd Semester'
        }


        if(semester == 3){
            return '3rd Semester'
        }


        if(semester == 4){
            return '4th Semester'
        }


        if(semester == 5){
            return '5th Semester'
        }


        if(semester == 6){
            return '6th Semester'
        }
    }
}


export const COURSE_TYPE_LIST = ["HONOURS","REGULAR"];
export const GENDER_LIST = ["MALE","FEMALE","OTHERS"];
export const CASTE_LIST = ["GENERAL","ST(P)","ST(H)","SC", "OBC"];
export const RELIGION_LIST = ["HINDUISM", "ISLAM", "CHRISTIANISM", "JAINISM", "SIKKHISM"];


export const MERIT_LIST_TYPE = [
    {
        text: 'GENERAL_MERIT_LIST_001',
        key: 'GENERAL_MERIT_LIST_001'
    },
    {
        text: 'GENERAL_MERIT_LIST_002',
        key: 'GENERAL_MERIT_LIST_002'
    },
    {
        text: 'GENERAL_MERIT_LIST_003',
        key: 'GENERAL_MERIT_LIST_003'
    },
    {
        text: 'OBC_MERIT_LIST_001',
        key: 'OBC_MERIT_LIST_001'
    },
    {
        text: 'OBC_MERIT_LIST_002',
        key: 'OBC_MERIT_LIST_002'
    },
    {
        text: 'OBC_MERIT_LIST_003',
        key: 'OBC_MERIT_LIST_003'
    },
    {
        text: 'STP_MERIT_LIST_001',
        key: 'STP_MERIT_LIST_001'
    },
    {
        text: 'STP_MERIT_LIST_002',
        key: 'STP_MERIT_LIST_002'
    },
    {
        text: 'STP_MERIT_LIST_003',
        key: 'STP_MERIT_LIST_003'
    },
    {
        text: 'STH_MERIT_LIST_001',
        key: 'STH_MERIT_LIST_001'
    },
    {
        text: 'STH_MERIT_LIST_002',
        key: 'STH_MERIT_LIST_002'
    },
    {
        text: 'STH_MERIT_LIST_003',
        key: 'STH_MERIT_LIST_003'
    },
    {
        text: 'SC_MERIT_LIST_001',
        key: 'SC_MERIT_LIST_001'
    },
    {
        text: 'SC_MERIT_LIST_002',
        key: 'SC_MERIT_LIST_002'
    },
    {
        text: 'SC_MERIT_LIST_003',
        key: 'SC_MERIT_LIST_003'
    },
    {
        text: 'EWS_MERIT_LIST_001',
        key: 'EWS_MERIT_LIST_001'
    },
    {
        text: 'QUOTA_MERIT_LIST_001',
        key: 'QUOTA_MERIT_LIST_001'
    }
]