import React, { Component, useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Typography,
  Avatar,
  CardContent,
  Select,
  Grid,
  withStyles,
  CardActions,
  Button,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  DialogActions,
  IconButton,
  DialogContent,
  TextField,
  ButtonGroup,
} from "@material-ui/core";
import AnalogClock, { Themes } from "react-analog-clock";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import moment from "moment";
import { ATTENDANCE_SLOTS, getGoodSemester } from "../../Helper/GlobalConstants";
import { Card as Card1 } from "antd";
import ExtraClassModal from "./ExtraClassModal";
import { GetData } from "../../api/service";
import {CheckSquareOutlined} from '@ant-design/icons'

export default function SlotAttendance({ username, apikey, history }) {
  const [open, setOpen] = React.useState(false);

  const [selectedSlot, setSelectedSlot] = React.useState(null);

  const [data,setData] = React.useState([])
  const [isLoaded, setIsLoaded] = React.useState(false)


  function loadMyClasses() {
    GetData(`/${username}/getmydoneclassestoday`)
    .then((resp) => {
      setData([...resp])
      setIsLoaded(true)
    })
  }

  React.useEffect(() => {
    loadMyClasses()
  }, [])

  function onAttendanceClick(el) {
    let ll = ATTENDANCE_SLOTS.find((e) => e.slotId == el.slotId);
    if (ll) {
      setSelectedSlot(ll);
      setOpen(true);
    }
  }

  function onCancel() {
    setOpen(false);
    setSelectedSlot(null);
  }


  function editAttendance (el){
    if (el != "") {
      history.push("/editattendance", el);
    }
  };


  function checkPeriodWiseList(slotId) {
    let d = data.filter((el) => el.period_number== slotId)
    if(d){
      return <div >
          {d.map((el,index) => <div style={{backgroundColor: "white", padding: 5, borderRadius: 5, fontSize: 12, display: "flex", justifyContent: "space-between", alginItems: "center", marginBottom: 5}} key={index}>
          <div>
          <CheckSquareOutlined style={{color: "green"}} />  {el.course}-{getGoodSemester(el.course,el.semester)} {el.sub_name}
          </div>
          <div>
            <a style={{color: "blue"}} onClick={() => editAttendance(el.id)}><u>Edit</u></a>
          </div>
          </div>)}
      </div>;
    }
  }

  return (
    <div>
      <Card raised={true}>
        <CardHeader
          avatar={<AnalogClock theme={Themes.light} width={60} />}
          title={
            <Typography variant="h5" style={{ fontWeight: 700 }}>
              Add Class Attendance for {moment().format("DD-MM-YYYY")}
            </Typography>
          }
          subheader={<>Click on the slots to add attendance for the subjects</>}
        />
        <CardContent>
          <Grid container spacing={2}>
            {ATTENDANCE_SLOTS.map((el, index) => (
              <Grid item xs={12} sm={12} md={3} lg={3} key={index}>
                <Card1 style={{ backgroundColor: "#F4ECF7" }}>
                  <Typography variant="overline">
                    Period: {el.slotId}
                  </Typography>
                  <Typography variant="body2" style={{ fontWeight: 700 }}>
                    {el.slotStart} - {el.slotEnd}
                  </Typography>
                  <br />
                  
                  
                  <a
                    style={{ color: "blue" }}
                    onClick={() => {
                      onAttendanceClick(el);
                    }}
                  >
                    Add Attendance
                  </a>
                  <br />
                  <br />
                  {checkPeriodWiseList(el.slotId)}
                </Card1>
              </Grid>
            ))}
          </Grid>
        </CardContent>

        
      </Card>

      {open && (
        <ExtraClassModal history={history} selectedSlot={selectedSlot} onCancel={onCancel}  />
      )}
    </div>
  );
}
