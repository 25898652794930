import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Grid, Card, CardContent, Typography, CardActions, Button, withStyles, CardHeader, Badge, Avatar, Fade } from '@material-ui/core';
import { GetData } from '../../api/service';
import { purple, red, pink, blue, teal, lightBlue, green } from '@material-ui/core/colors';
import RoutineLanding from '../Routine/RoutineLanding';
import {connect} from 'react-redux'
import SlotAttendance from '../Routine/SlotAttendance';


const styles = theme => ({
    card: {
        minWidth: 275,
    },
    details: {
        display: 'flex',
        flexDirection: 'row'
    },
    content: {
        flex: '1 0 auto'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 22,
    },
    pos: {
        marginBottom: 12,
    },
    backPrimary: {
        backgroundColor: '#d500f9',
        color: 'white',
        display: 'flex',
        justifyContents: 'center',
        alignItems: 'center'
    },
    backSecondary: {
        backgroundColor: '#e91e63',
        color: 'white',
        display: 'flex',
        justifyContents: 'center',
        alignItems: 'center'
    },
    backSec: {
        backgroundColor: '#009688',
        color: 'white',
        display: 'flex',
        justifyContents: 'center',
        alignItems: 'center'
    },
    approve: {
        backgroundColor: purple['A400']
    },
    approveGrid: {

    },
    bigCard: {
        backgroundColor: lightBlue[600],
    },

});

const colorArr = [purple['A200'], red[500], pink['A200'], blue['A400'], teal[700]]

function mapDispatchToProps(dispatch) {
    return {
        
    }
}

const mapStateToProps = state => {
    return { leaveBalance: state.leaveBalance };
};

class TopLabel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
            isLoaded: true
        }


    }

    componentDidMount() {


              
   



    }


    render() {
        const { classes } = this.props;

        let i = [];
        if (this.state.isLoaded) {
            let leave = this.props.leaveBalance;
            if (Array.isArray(leave) && leave.length > 0) {
                i = leave.map((el, index) =>

                    <Fade in={true} key={index}>

                        <Grid item xs={4} lg={2}>

                            {/* <Card
                                raised
                                style={{
                                    backgroundColor: colorArr[Math.floor(Math.random() * colorArr.length)],
                                    color: 'white'
                                }}
                            >
                                <CardHeader
                                    title={el.balance}
                                    subheader={el.leave_desc}
                                    subheaderTypographyProps={{
                                        color: 'white'
                                    }}
                                />
                            </Card> */}

                        </Grid>

                    </Fade>
                )
            }
        }

        return (
            <div>

                {/* {this.props.is_teaching ? <RoutineLanding
                    username={this.props.username}
                    apikey={this.props.apikey}
                    history={this.props.history} /> : null} */}


                    {this.props.is_teaching ? (
          <SlotAttendance
            username={this.props.username}
            apikey={this.props.apikey}
            history={this.props.history}
          />
        ) : null}

                <br />

                <Grid container spacing={2}>
                    {i}
                </Grid>

            </div >
        )
    }
}



const TopLabel1 = connect(
    mapStateToProps,
    mapDispatchToProps
)(TopLabel);

export default withStyles(styles)(TopLabel1);


